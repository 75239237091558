@mixin viewport($size:large) {

    @if $size == small {
        @media screen and (max-width : 480px){
            @content
        }
    } @else if $size == medium {
        @media screen and (max-width : 768px){
            @content
        } 
    }
}



