@import '../../styles/viewports.scss';

section{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    min-height: calc(100vh - 100px);
    padding: 60px 0;
    position: relative;

    @include viewport(medium) {
    }

    &.sample-work {
        flex-direction: column;
    }
}

@include viewport(small) {
    section {
        display: block;
        font-size: 1em;

        img {
            width: 100%;
        }
        
    }


}

.intro {
    display: block;
    text-align: center;

    h2 {
        font-size: 3em;
        margin: 0;

        + p {
            margin: 0;
        }
    }
    .content {
        background-color: rgba(26, 29, 34, .5); 
        background-image: linear-gradient(rgba(35,39,43, .5), rgba(24, 28, 33, .5));
        margin-top: -60px;
        padding: 25px 0;
        bottom: 0;
    }

    .nav-cards {
        margin: 0 1em;
        padding: 25px;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:3.5em;
        text-align: left;

        li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                min-height: 150px;
            }
            a {
                border: 1px solid #fff;
                padding: 15px;
                text-decoration: none;
                color: #fff;
                transition: transform 200ms ease;
                border-radius: 5px;
                box-shadow: 0 0 3px aqua;
                &:hover {
                    color: #4eacbd;
                    transform: scale(1.1);
                    border-color: #4eacbd;
                    text-decoration: underline;
                }
            }
        }

        @include viewport(small) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            li p {
                min-height: 0;
            }
        }

        @media (hover: none) {
}

    }

    .bold-italic {
        font-weight: 800;
        font-style: italic;
        color: #4eacbd;
    }


    .social-media-links {
        display: flex;
        list-style: none;
        justify-content: center;
        align-items: center;
        gap: 15px;
        a{
            svg {
                fill: #4eacbd;
                height: 50px;
                width: 50px;
                transition: transform 250ms ease-in-out;
            }

            &:hover svg {
                fill: #fff;
                transform: translateY(-5px);
            }
        }
    }

    p.slide-up {
        margin: 10px 0;
        overflow: hidden;

        .slide-content {
            transform: translateY(200%);
            display: block;
            animation: slide-up 1000ms ease-in-out 1s 1 forwards;
            border-image: linear-gradient(#0003, #000);
        }

        &.line-1 .slide-content {
            animation-delay: 1500ms;        
        }
    }

    @include viewport(small) {
        font-size: 1rem;
    }
}

img.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 55vh;
    max-height: 850px;
}

.bold-italic {
    font-weight: 800;
    font-style: italic;
    color: #4eacbd;
}

.hero::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(78, 172, 189, 0.3) 10%, rgba(0, 0, 0, 0.8) 45%, rgba(78, 172, 189, 0.3) 80%), url('/styles/images/about-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.about {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(4, min-content);
    min-width: 320px;
    max-width: 1440px;
    width: 80%;
    column-gap: 50px;
    font-size: 1.1em;

    div:first-child {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 5;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            background-color: #4eacbd;
            top: 25px;
            left: 25px;
            z-index: -1;
            aspect-ratio: 1/1;
        }
    }

    div:nth-child(2) {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    div:nth-child(3) {
        grid-column-start: 2;
        grid-row-start: 3;
    }

    div:nth-child(4) {
        grid-column-start: 2;
        grid-row-start: 4;
    }

    div:nth-child(5) {
        grid-column-start: 3;
        grid-row-start: 4;
    }

    h2 {
        font-size: 3em;
        overflow: hidden;
        margin-bottom: 10px;
    }

    img {
        position: relative;
        aspect-ratio: 1/1;
        height: auto;
        object-fit: cover;
        object-position: 0 0;
        width: 100%;
        box-shadow: 2px 2px 2px #000;
    }

    @include viewport(small) {
        display: block;
        padding: 60px 15px;
    }
}


.portfolio {
    h2 { 
        text-align: center;
    }
}
@keyframes slide-up {
    to {
        transform: translateY(100%);
    }
}

@keyframes scroll {
    to {
        transform: translateX(-700%);
    }    
}