@import './styles/viewports.scss';
@import './styles/colors.scss';
@import './styles/sample-work.scss';

$header-height: 75px;

body {
    @include color(darkest);
    font-family: 'Josefin Sans', sans-serif;
}

html {
    scroll-padding-top: $header-height;
    scroll-behavior: smooth;
}

#root {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.sc5 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

main {
    max-width: 1440px;
    min-width: 320px;
    width: 100%;
}



footer{
    margin: 50px 0 10px;
    font-size: .75em;
    a {
        color: #4eacbd;
    }
}