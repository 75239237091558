code {
    display: block;
    font-style: italic;
    margin: 10px;
    font-size: .75rem;
    white-space: nowrap;
}

.css-tricks {
    [data-hidden='true'] > div {
        display: none;
    }
}