$darkest: #000000;
$dark: #2F4550;
$medium: #586F7C;
$light: #B8DBD9;
$lightest: #F4F4F9;

@mixin color($level:medium) {
    @if $level == darkest {
        background-color: $darkest;
        color: #FFFFFF;
    } @else if $level == dark {
        background-color: $dark;
        color: #FFFFFF;
    } @else if $level == medium {
        background-color: $medium;
        color: #FFFFFF;
    } @else if $level == light {
        background-color: $light;
        color: #000000;
    } @else if $level == lightest {
        background-color: $lightest;
        color: #000000;
    }
}