.translucent-borders {
    .image-container {
        display: block;
        height: 350px;
        margin: 25px auto;
        position: relative;
        width: 640px;
        overflow: hidden;
        img {
            height: auto;
            width: 100%;
        }
        .content {
            background-color: #bbb;
            border: 15px solid hsla(0, 0%, 100%, .5);
            border-radius: 25px;
            left: 50%;
            padding: 25px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .dashed-border .content{
        border-style: dashed;
        background-color: green;
        color: #fff;
    }

    .background-clip .content {
        background-clip: padding-box;
    }
}