@mixin sample-work() {
    img {
        width: 100%;
        height: auto;
        border-radius: 25px;

    }

    .fade {
        display: block;
        animation-iteration-count: 1;
        animation-duration: 2s;
        margin: 10px 0;
    }

    .slide-down {
        animation-name: fade-in, slide-down;
    }

    .slide-up {
        animation-name: fade-in, slide-up;
    }

    .scroll-down {
        animation-delay: 3s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-play-state: paused;
        animation-timing-function: linear;
        animation: scroll-down 100s;
        grid-area: "scroll-down";
        max-height: 600px;
        object-fit: cover;
        object-position: 0 0;
        width: 100%;
        &:hover {
            animation-play-state: running;            
        }
    }

    .hover-card {

        p {
            background-color: $darkest;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            + .scroll-down {
                animation-play-state: paused;
            }
        }

        &:hover {
            p {
                opacity: 1;

                + .scroll-down {
                    animation-play-state: running; 
                }
            }
        }
    }

    .hover-card, img {
        &:hover {
            box-shadow: 2px 2px 2px lightblue;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes scroll-down {
    0% {
        object-position: 0 0;
    }

    100% {
        object-position: 0 100%;
    }
}