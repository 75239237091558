@import '../../styles/colors.scss';
@import '../../styles/viewports.scss';

$header-height: 75px;
$color: #4eacbd;

@mixin hover-menu {
    background: #000;
    height: calc(100vh - 3px);
    border: 1px solid #4eacbd;
    transition: height 500ms ease-in;
    text-align: right;

}

header {
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .7), rgba(0, 0, 0, .3));
    border-bottom: 2px solid $color;
    color: $color;
    display: flex;
    height: $header-height;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .logo {
        align-items: center;
        filter: invert(60%) sepia(29%) saturate(694%) hue-rotate(141deg) brightness(96%) contrast(91%);
        display: flex;
        gap: 10px;
        height: 65px;
        text-decoration: none;
        margin: 0 1em;

        .logo-image {
            transition: transform 250ms ease-out;
            max-height: 55px;
        }

        &:hover {
            .logo-image {
                fill: #fff;
                transform: scale(1.1);
            }
        }
    }

    nav {
        min-width: 320px;
        max-width: 1440px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
            display: none;
        }
        ul {
            margin: 0;
            align-items: center;
            display: flex;
            gap: 15px;
            list-style: none;
            padding: 0;
            margin-right: 1em;

            &::after {
                display: none;
            }
        }

    }

    @include viewport(medium) {
        nav {
            position: relative;
    
            button {
                background: transparent;
                display: block;
                border: 0;
                margin: 1em;
                svg {
                    fill:#4eacbd;
                }
            }
            ul {
                display: block;
                position: absolute;
                right: 0;
                top: 1px;
                width: 100%;
                height: 0;
                overflow: hidden;
                background-color: #000000;
                text-align: center;
                margin: 0;
                box-sizing: border-box;
                li {
                    padding: 15px;
                }
    
                &:hover {
                    @include hover-menu();
                }
            }
    
    
            button:hover,
            button:active {
                + ul {
                    @include hover-menu();
                }
            }
        }
    }

}

.hover-underline {
    color: $color;
    position: relative;
    text-decoration: none;
    transition: transform 200ms ease-in-out;
    padding: 3px 0;

    &:hover {
        color: #fff;
        transform: translateY(-2px);
    }

    &::after {
        background: none repeat scroll 0 0 transparent;
        background-color: $color;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover::after {
        left: 0;               
        width: 100%; 
        background-color: #fff;
    }
}
