.multiple-borders {
    .content {
        background-color: white;
        margin: 10px auto;
        width: 320px;

        &.box-shadow {
            border-radius: 25px;
            border: 30px solid black;
            box-shadow: 0 0 0 10px red,
                0 0 0 20px orange,
                0 0 0 30px yellow,
                0 0 0 40px green,
                0 0 0 50px blue,
                0 0 0 60px violet;    
            margin-bottom: 85px;
        }

        &.outline {
            border: 10px solid black;
            outline: 10px solid red;
            margin-bottom: 40px
        }

        &.outline-offset {
            outline-offset: 10px;
        }
    }
}