@import '../../styles/viewports.scss';

.skills {
    .content {
        background-color: rgb(26, 29, 34); 
        background-image: linear-gradient(rgb(35,39,43), rgb(24, 28, 33));
        width: 100%;
        transition: bottom 500ms ease-in-out, opacity 500ms ease-in;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    h2 {
        margin-top: -25px;
        font-size: 4em;
    }

    ul {
        margin: 0;
        list-style: none;
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(6, min-content);
        grid-template-rows: repeat(7, 1fr);

        @include viewport(small) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            overflow-y: scroll;
        }

        li {
            background-color: #fff;
            position: relative;
            padding: 5px;
            border-radius: 5px;
            transition: transform 250ms ease-in;
            border: 2px solid transparent;
            &:hover {
                border-color: #4eacbd;
                transform: translateY(-10px);
            }

            &.skill-logo-long {
                grid-column: span 2;

                @include viewport(small) {
                    grid-column: span 1;
                    display: flex;
                    img {
                        height: 30px;
                    }
                }
            }
        }
        p {
            margin: 0;
            position: absolute;
            bottom: 0;
        }
        img {
            height: 60px;
            width: auto;
            margin: auto;
            text-align: center;
            display: block;
        }
    
    }
    a {
        font-size: 1.1em;
        font-weight: 600;
        color: #fff;
        margin: 10px 0;
    }
}