.resume {
    .list-section {
        list-style: none;
    }

    .experience {
        .list-section {
            list-style: disc;
        }
    }
}