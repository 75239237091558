@import '../../styles/viewports.scss';

.gallery {
    list-style: none;
    margin: 80px 0;
    padding: 0;
    .card {
        border-radius: 0;
        border: 0;
        display: grid;
        gap: 5px;
        grid-template-rows: 1fr;
        transition: transform 250ms ease-in-out;
        &:hover{
            box-shadow: 0px 0px 2px #000;
            transform: scale(1.05);
        }

        button {
            background-color: #fff;
            border: 1px solid transparent;
            cursor: pointer;
            color: #232023;
            font-size: 1em;
            font-weight: 700;
            padding: .5em;
            text-transform: uppercase;
            transition: background-color 250ms ease-in-out, color 250ms ease-in-out, transform 250ms ease-in-out, border-color 250ms ease-in-out;
            &:hover{
                background-color: #232023;
                border-color: grey;
                color: #fff;
                transform: scale(1.02);
            }
        }

        @media (hover: none) {
            transform: none;
            button {
              transform: none;
            }
          }
          
        img {
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: 0 0;
            width: 100%;
        }

        .card-info{
            border: 1px solid white;
            display: flex;
            flex-direction: column;
            .title {
                display: block;
                font-size: 1.25em;
                font-weight: bold;
            }
            .description {
                background-color: #232023;
                color: #fff;
                flex-grow: 1;
                font-weight: 600;
                margin: 0;
                padding: 15px;
            }
        }
    }
    
    &.grid {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);

        @include viewport(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include viewport(small) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.carousel {
        display: flex;
        overflow-x: auto;
        aspect-ratio: 16 / 9;
        scroll-snap-type: x mandatory;
        gap: 15px;
        scroll-behavior: smooth;
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        .card {
            flex: 1 0 100%;
            scroll-snap-align: start;
        }
    }
}

.gallery-wrapper {
    margin: 0 auto;
    max-width: 90%;
    position: relative;
}

.modal {
    width: 80%;
    min-height: 40%;
    top:0;
    text-align: center;
    border: 2px solid #4eacbd;
    img {
        width: 90%;
        aspect-ratio: 16/9;
        object-fit: cover;
        object-position: top;
        animation: scroll-down 120s linear forwards;
        animation-play-state: paused;
    }

    button.close {
        float: right;
        border: 0;
        background: none;
        cursor: pointer;
    }

    &:hover {
        img {
            animation-play-state: running;
        }
    }

    &::backdrop {
        filter: blur();
    }

    p a {
        margin-left: .5em;
        text-decoration: none;
        color: #4eacbd;
        &:visited {
            color: #4eacbd;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

@keyframes scroll-down {
    from {
        object-position: top;
    }

    to {
        object-position: bottom;
    }
}